<template>
  <page-main
    title="This is your Work History"
    :aside="['']"
    nextText="All recorded? Almost done - Final information is next."
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/FinalInfo')"
    @save="save()"
  >
    <v-container fluid class="container-main">
      <v-row>
        <v-col
          cols="12"
          class="r-col"
          v-for="(item, i) in fullList"
          :key="item.key"
        >
          <list-card
            :title="item.general"
            @orderup="orderUp(item.key)"
            @orderdown="orderDown(item.key)"
            :disableUp="i === 0"
            :disableDown="i === fullList.length - 1"
          >
            <div class="d-flex justify-space-between align-center">
              <div>
                <div v-if="HasValue(item, 'roles')">
                  <div v-for="(role, i) in item.roles" :key="i">
                    {{ role }}
                  </div>
                </div>
                <div v-if="HasValue(item, 'date')">
                  {{ item.date }}
                </div>
                <div v-if="HasValue(item, 'location')">
                  {{ item.location }}
                </div>
                <div
                  v-if="
                    HasValue(item, 'responsibilities') ||
                      HasValue(item, 'directReports')
                  "
                  class="pt-2"
                >
                  Responsibilities:
                  <div
                    v-if="HasValue(item, 'responsibilities')"
                    class="text-body-2 pl-2"
                  >
                    {{ item.responsibilities }}
                  </div>
                  <div
                    v-if="HasValue(item, 'directReports')"
                    class="text-body-2 pl-2"
                  >
                    {{ item.directReports }}
                  </div>
                </div>
                <div v-if="HasValue(item, 'achievements')" class="pt-2">
                  Achievements:
                  <div class="pl-2">
                    <span
                      v-for="ach in item.achievements"
                      :key="ach"
                      class="text-body-2"
                    >
                      {{ ach }}
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column align-start">
                <v-btn
                  tile
                  text
                  @click="navWithKey('/WorkCompanyGeneral/', item.key)"
                >
                  <v-icon class="pr-2"> mdi-pencil-outline </v-icon>
                  Edit
                </v-btn>
                <v-btn tile text @click.stop="launchDialog(item)">
                  <v-icon class="pr-2">mdi-delete</v-icon>
                  Remove
                </v-btn>
              </div>
            </div>
          </list-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-4">
        <v-col cols="auto">
          <v-btn outlined @click="navNewRecord()">Add Work Record</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <r-dialog v-model="dialog" title="You are about to remove:">
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary">{{ dialogDisplay }}</p>
        <span class="text-body-2">Are you sure?</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray" text @click="dialog = false"> Cancel </v-btn>
        <v-btn color="error" text @click="removeDialogItem()"> Remove </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import * as uuid from "../utility/guid";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListCard from "../components/ListCard.vue";
import { HasValue } from "../utility/general";

// @ is an alias to /src
export default {
  name: "Work",
  data: () => ({
    dialog: false,
    dialogKey: "",
    dialogDisplay: ""
  }),
  components: {
    "list-card": ListCard
  },
  mounted() {
    if (this.fullList?.length > 0) {
      this.key = this.fullList[0].key;
    }
  },
  methods: {
    save() {
      this.saveWork();
    },
    ...mapMutations("work", [
      "createEntry",
      "removeEntry",
      "orderUp",
      "orderDown"
    ]),
    ...mapActions("work", ["saveWork"]),
    HasValue,
    navWithKey(path, key) {
      this.key = key;
      this.nav(path + key);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    navNewRecord() {
      this.key = uuid.gen();
      this.createEntry({ key: this.key });
      this.$router.push({ path: "/WorkCompanyGeneral/" + this.key });
    },
    launchDialog(item) {
      this.dialogDisplay = item.general;
      this.dialogKey = item.key;
      this.dialog = true;
    },
    removeDialogItem() {
      this.removeEntry({ key: this.dialogKey });
      this.dialog = false;
    },
    roleDisplay(roles) {
      return roles?.reduce((p, c) => `${p}, ${c}`);
    }
  },
  computed: {
    ...mapGetters("work", ["fullList"]),
    ...mapFields("work", { key: "key" }),
  }
};
</script>
